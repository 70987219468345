import '../css/load.scss';

(function ($) {
	const $win = $(window);
	const $doc = $(document);

	initHeaderMenuToggler();
	initLanguageSwitcher();

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initLanguageSwitcher() {
		if (!$('#lang-switcher-btn').length) {
			return;
		}

		const $langSwitcher = $('#lang-switcher');

		$('#lang-switcher-btn').on('click', function (e) {
			e.preventDefault();

			$langSwitcher.toggleClass('active');
			$('.app-loader-overlay').css('z-index', '999997')
			addOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($langSwitcher.hasClass('active')) {
				if (!$langSwitcher.is(e.target) && $langSwitcher.has(e.target).length === 0) {
					$langSwitcher.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101')
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}
})(jQuery);
